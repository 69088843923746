import React from 'react';
import './styles.css'
import { Element } from 'react-scroll';

import Logo from '../../assets/logo.png'
function Footer() {
    return (
        <div className="footer">
            <img src={Logo} alt="Logo-footer" />
            <Element name="fourth" />
            <div className="items">
                <ul>
                    <li>Contato</li>
                    <li>(83)3512-0395 - Televendas e Atendimento</li>
                    <li><a href="https://api.whatsapp.com/send?phone=5583998590193" rel="noreferrer" target="_blank" >(83)99859-0193</a> - WhatsApp</li>
                    <br />
                    <li>Localização</li>
                    <li><a href="https://goo.gl/maps/ZcH1LsLNPMg35tvZA" rel="noreferrer" target="_blank">R. Professora Maria Vilani Benício Alves, 499</a></li>
                    <li><a href="https://goo.gl/maps/ZcH1LsLNPMg35tvZA" rel="noreferrer" target="_blank">Mangabeira VII, João Pessoa - PB, 58058-592</a></li>
                    <br />
                    <li>Horário de funcionamento</li>
                    <li>Seg. a Sex. das 07:00 às 15:00 </li>
                    <li>Atendemos aos sábados com agendamento prévio </li>
                </ul>
                <ul>
                    
                </ul>
            </div>
        </div>
    )
}

export default Footer;