import React from 'react';
import CarouselNuka from 'nuka-carousel';
import './styles.css'
function Carousel({children}){
    let Controls = false
    return (
            <CarouselNuka 
            style={{ width: "90vw", height: "50vh"}}
            cellSpacing={0} 
            autoplayInterval={5000} 
            autoplay={true} 
            wrapAround={true}
            slidesToShow={1}
            withoutControls={Controls}
            cellAlign="center"
            defaultControlsConfig={{
                nextButtonText: 'Próx.',
                prevButtonText: 'Voltar',
                nextButtonClassName: 'buttonCarousel',
                prevButtonClassName: 'buttonCarousel',
                pagingDotsStyle: {
                  margin: '5px',
                  fill: 'white'
                }
              }}
            >
                { children }
            </CarouselNuka>
    )
}
export { Carousel };