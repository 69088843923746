import React from 'react';
import Footer from '../footer';
import Navbar from '../navbar';
import "./styles.css"

function DefaultPage({children}) {
    return (
        <div className="main">
            <div className="page">
            {children}
            </div>
            <Footer />
            <Navbar />
        </div>
    )
}

export default DefaultPage;