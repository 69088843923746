import React from 'react';
import DefaultPage from '../../components/pageDefault'
import { Carousel } from './carousel';
import { Element } from 'react-scroll';


import "./styles.css"
//import imagens
//logos
import WhatsAppLogo from '../../assets/whatsapp-logo-icone.png'
import DriverLogo from '../../assets/icon-motorista.png'
import BusinesLogo from '../../assets/icon-empresa.png'
import ConcourseLogo from '../../assets/icon-concurso.png'
//banner
import Banner from '../../assets/banner.jpg'
import Banner2 from '../../assets/banner2.jpg'
import { Card } from './card';

function Home() {
    const docWidth = document.getElementsByTagName('body')[0].clientWidth
    var boxesToShow
    var boxes = [
            <Card title="Para CNH C, D e E" logo={DriverLogo} logoName="DriverLogo" />,
            <Card title="Para Empresas" logo={BusinesLogo} logoName="BusinesLogo" />,
            <Card title="Para Concursos" logo={ConcourseLogo} logoName="ConcourseLogo" />]
    if (docWidth < 800) {
        //é celular
        boxesToShow = <Carousel>
                    {boxes.map(box =>{ return box})}
                    </Carousel>
    }
    else {
        //é pc
        boxesToShow = <>{boxes.map(box =>{ return box})}</>
    }
    return (
        <DefaultPage>
            <div className="home">
                <img src={Banner} rel="preload" alt="banner" id="banner1" />
                <div className="headerPage">
                    {/* boxes */}
                    <Element name="first" />
                    <h1>Exames Toxicológicos na LaToxi</h1>
                    <div className="boxes">{boxesToShow}</div>
                </div>
                    <img src={Banner2} rel="preload" alt="banner2" id="banner2" />
                    <Element name="second" />
                <div className="boxes second">
                    {/* lei 14.071/20 */}
                    <h1>Você conhece a nova Lei 14.071/20?</h1>
                    <h2>Condutores das categorias C, D e E, com idade inferior a 70 anos deverão realizar o exame toxicológico:</h2>
                    <h2>- A cada <strong>2 anos e 6 meses</strong> para CNH com validade de 5 anos.</h2>
                    <h2>- A cada <strong>1 ano e 6 meses</strong> para CNH com validade de 3 anos.</h2>
                    <br />
                    <h2>O condutor que não realizar o exame toxicológico em até 30 dias após o vencimento dos prazos
                        de 2 anos e 6 meses ou de 1 ano e 6 meses, previsto no <strong>Art. 148-A</strong>, cometerá infração gravíssima.
                    </h2>
                    <br />
                    <h2>A punição para esta conduta será <strong>multa de R$1.467,35</strong> e <strong>suspenção do direito de dirigir por 03 meses.</strong>
                        Isso também vale para condutores que exercem atividade remunerada.
                    </h2>
                    <br />
                    <h2 style={{color: "var(--primary-bg-color)"}}>
                        Estes exames deverão ser lançados no Renach e para tal devem ser cadastrados em nossos Sistemas com a finalidade CNH.
                    </h2>
                    <br />
                    <h2>
                        <strong style={{color: "var(--primary-bg-color)"}}>ATENÇÃO:</strong> a Validade e a janela de Detecção do Exame Toxicológico permanecem as mesmas,
                        90 dias contatos a partir da Data de Coleta.
                        </h2>
                </div>
                <div className="boxes">
                    {/* localização */}
                    <Element name="third" />
                    <div className="text2">
                        <div className="list">
                                <h2>Nossa Localização fica próximo ao Detran para o seu conforto e comodidade.</h2>
                        </div>
                        <div className="buttonS">
                            <img src={WhatsAppLogo} alt="whatsapp-logo-icon" />
                            <a href="https://api.whatsapp.com/send?phone=5583998590193" rel="noreferrer" target="_blank" >Fale conosco</a>
                        </div>
                    </div>
                    <div className="box2">
                        <a href="https://goo.gl/maps/ZcH1LsLNPMg35tvZA" rel="noreferrer" target="_blank"><h2>Localização</h2></a>
                        <iframe title="GMaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.656103763397!2d-34.82747286558152!3d-7.165694945701799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xda2ef0ed03f72634!2sLaToxi%20Exames%20Toxicol%C3%B3gicos!5e0!3m2!1spt-BR!2sbr!4v1617488523068!5m2!1spt-BR!2sbr"  allowFullScreen="no" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </DefaultPage>
    )
}

export default Home;