import React from 'react';

import Elo from '../../../assets/bandeiras/elo.png'
import Master from '../../../assets/bandeiras/master.png'
import Visa from '../../../assets/bandeiras/visa.png'
import Hiper from '../../../assets/bandeiras/hiper.png'
import American from '../../../assets/bandeiras/american.png'
import Boleto from '../../../assets/bandeiras/boleto.png'
import Pix from '../../../assets/bandeiras/pix.png'

import WhatsAppLogo from '../../../assets/whatsapp-logo-icone.png'
function Card(props){
    const {title, logo, logoName} = props
    return (
            <a href="https://api.whatsapp.com/send?phone=5583998590193" rel="noreferrer" target="_blank" style={{textDecoration: "none", color: 'white'}}>
        <div className="box" key="1">
        <div className="boxHeader">
            <h2>{title}</h2>
        </div>
        <div className="boxBody">
            <img src={logo} alt={logoName} />
        </div>
        <div className="boxFooter">
            <h3>Em até 10x sem Juros</h3>
            <div className="payment">
                <ul className="paymentMethods">
                    <li><img src={Elo} alt="elo" /></li>
                    <li><img src={Master} alt="master" /></li>
                    <li><img src={Visa} alt="visa" /></li>
                    <li><img src={Hiper} alt="hiper" /></li>
                    <li><img src={American} alt="american" /></li>
                    <li><img src={Boleto} alt="boleto" /></li>
                    <li><img src={Pix} alt="Pix" /></li>
                </ul>
            </div>
            <div className="buttonS">
                <img src={WhatsAppLogo} alt="whatsapp-logo-icon" />
                <a href="https://api.whatsapp.com/send?phone=5583998590193" rel="noreferrer" target="_blank" >Agendar agora</a>
            </div>
        </div>
        </div>
        </a>
    )
}
export { Card };