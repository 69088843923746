import React, { useState } from 'react';
import './styles.css'
import Logo from '../../assets/logo.png'
import * as Scroll from 'react-scroll';

let SLink = Scroll.Link;

function Navbar({children}) {
    const [menu, setMenu] = useState(false)
    function hamburguerMenu(){
        if (menu === false) {
            document.querySelector('.links').classList.add('active')
            document.querySelector('.links').classList.remove('desactive')
            setMenu(true)
        }
        if (menu === true) {
            document.querySelector('.links').classList.remove('active')
            document.querySelector('.links').classList.add('desactive')
            setMenu(false)
        }
    }
    return (
        <div className="navbar">
            <SLink activeClass="active" to="first" spy={true} offset={-90} smooth={true} duration={500} ><img src={Logo} alt="Logo" /></SLink>
            <div className="links desactive">
                <SLink activeClass="active" onClick={hamburguerMenu} to="first" spy={true} offset={-90} smooth={true} duration={500} >Exames</SLink>
                <SLink activeClass="active" onClick={hamburguerMenu} to="second" spy={true} offset={-90} smooth={true} duration={500} >Lei 14.071/20</SLink>
                <SLink activeClass="active" onClick={hamburguerMenu} to="third" spy={true} offset={-90} smooth={true} duration={500} >Localização</SLink>
                <SLink activeClass="active" onClick={hamburguerMenu} to="fourth" spy={true} offset={-90} smooth={true} duration={500} >Contato</SLink>
            </div>
            <div className="hamburguer" onClick={hamburguerMenu}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </div>
    )
}

export default Navbar;